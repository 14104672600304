import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import useComponentVisible from '../../../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../../../utils/buildLocale';
import { actionsFilters, selectorsFilters } from '../../../../../redux/filters/filtersReducer';
import { SortingValues } from '../../../../../typings/CustomTypes/SortingValues';

interface IProps {
  sortProducts?: (sortData: string | undefined) => void;
}

const SortComponentDesktop = (props: IProps) => {
  const { sortProducts } = props;
  const dispatch: any = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const sortDirectionFilter = useSelector(selectorsFilters.getSortDirection);
  const [isOpen, setIsOpen] = useState(false);

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
    }
  }, [isComponentVisible]);

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
  };

  const changeSorting = (sortData: string | undefined) => {
    if (sortData === undefined) {
      dispatch(actionsFilters.setSortDirection(undefined));
      if (sortProducts) {
        sortProducts(sortData);
      }
      return;
    }

    dispatch(actionsFilters.setSortDirection(sortData));

    if (sortProducts) {
      sortProducts(sortData);
    }
  };

  return (
    <div
      className="catalog-sort_drop"
      ref={inputRef}
      onClick={() => {
        handleToggle();
        setIsOpen(!isOpen);
      }}>
      <span className="catalog-sort_caption">{buildLocale(currentTranslate, 'filtersSortTitle')}</span>
      {isOpen && (
        <div className="category-radio sorting-items" onClick={(e) => e.stopPropagation()}>
          <div className="form-radio">
            <input
              type="radio"
              id="default"
              checked={sortDirectionFilter === undefined}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(undefined);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="default">{buildLocale(currentTranslate, 'categoriesFilterPopular')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="highPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.ascPrice)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.ascPrice);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="highPrice">{buildLocale(currentTranslate, 'highPriceFilter')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descPrice)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.descPrice);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="lowPrice">{buildLocale(currentTranslate, 'lowPriceFilter')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowDiscountPercentSorting"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descDiscount)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.descDiscount);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="lowDiscountPercentSorting">
              {buildLocale(currentTranslate, 'lowDiscountPercentSorting')}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortComponentDesktop;
