import { useSearchParams } from 'react-router-dom';
import { IAttributeMain, ISelectedFilters } from '../typings/IFilters';
import { FilterSearchingParams } from '../redux/filters/filtersTypes';
import useApi from 'react-use-api';
import { IUseApiQuery } from '../typings/IUseApiQuery';
import queries from '../services/queries';
import { IBrandItem } from '../typings/IBrands';
import { decodeUrlTailParamsForFilters, encodeUrlTailParamsForFilters } from '../utils/convertUrlTailParamsForFilters';

const useFilterDataFromSearchParams = (
  brandsQuery: IUseApiQuery,
  badgesQuery: IUseApiQuery,
  attributesQuery: IUseApiQuery,
) => {
  const [searchParams] = useSearchParams();
  const queryBrands = searchParams.get('brands');
  const queryBadges = searchParams.get('badges');
  const queryAllergens = searchParams.get('allergens');
  const queryNutrition = searchParams.get('nutrition');
  const queryHasPriceRanges = searchParams.get('haspriceranges') || searchParams.get('hasPriceRanges');
  const queryHasBadges = searchParams.get('hasbadges') || searchParams.get('hasBadges');
  const queryHasSalePrice = searchParams.get('hassaleprice') || searchParams.get('hasSalePrice');
  const querySearchFilter = searchParams.get('searchfilter') || searchParams.get('searchFilter');
  const queryProductSearchFilter = searchParams.get('search');
  const querySortDirection = searchParams.get('sort');
  const queryCustomCategory = searchParams.get('customcategory') || searchParams.get('customCategory');

  const handleData = (data: any, state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }

    const { items } = data;
    return [
      ...items.map((brand: IBrandItem) => {
        brand.nameDe = brand.name;
        return brand;
      }),
    ];
  };

  const brandsOptions = { skip: !queryBrands?.length, handleData };
  const searchingBrandsQuery = queryBrands?.length
    ? decodeUrlTailParamsForFilters(queryBrands.toLowerCase()).split(',')
    : [];
  const [brandsToSearch] = useApi(queries.getBrandsByNames(searchingBrandsQuery), brandsOptions);

  const badgesOptions = { skip: !queryBadges?.length, handleData };
  const searchingBadgesQuery = queryBadges?.length
    ? decodeUrlTailParamsForFilters(queryBadges.toLowerCase()).split(',')
    : [];
  const [badgesToSearch] = useApi(queries.getBioBadgesByNames(searchingBadgesQuery), badgesOptions);

  const [brands] = useApi(brandsQuery.query, brandsQuery.options);
  const [badges] = useApi(badgesQuery.query, badgesQuery.options);
  const [attributes] = useApi(attributesQuery.query, attributesQuery.options);
  const allergich = attributes?.find((item: IAttributeMain) => item.nameEn === 'Allergic Information')?.attributes;
  const nutrion = attributes?.find((item: IAttributeMain) => item.nameEn === 'Nutrition Information')?.attributes;

  const isQueryTailExists = (): boolean => {
    return [
      queryBrands,
      queryBadges,
      queryAllergens,
      queryNutrition,
      queryHasPriceRanges,
      queryHasBadges,
      queryHasSalePrice,
      querySearchFilter,
      queryProductSearchFilter,
      querySortDirection,
      queryCustomCategory,
    ].some((value: string | boolean | null) => value !== null);
  };

  const searchQueryData: FilterSearchingParams = {
    selectedBrands: [],
    selectedBadges: [],
    selectedAllergich: [],
    selectedNutrion: [],
    hasPriceRanges: false,
    hasBadges: false,
    hasSalePrice: false,
    searchFilter: null,
    productSearchFilter: null,
    sortDirection: undefined,
    customCategory: null,
    isEmpty: !isQueryTailExists(),
  };
  if (searchQueryData.isEmpty) {
    return { brands, badges, allergich, nutrion, searchQueryData };
  }
  if (queryBrands?.length && (brandsToSearch?.length || brands?.length)) {
    const searchingBrands: ISelectedFilters = brandsToSearch?.length
      ? brandsToSearch
      : brands.filter((brand) => {
          return queryBrands
            .toLowerCase()
            .split(',')
            .includes(encodeUrlTailParamsForFilters(brand.nameDe).toLowerCase());
        });
    if (searchingBrands?.length) {
      searchQueryData.selectedBrands = searchingBrands;
    }
  }

  if (queryBadges?.length && (badgesToSearch?.length || badges?.length)) {
    const searchingBadges: ISelectedFilters = badgesToSearch?.length
      ? badgesToSearch
      : badges.filter((badge) =>
          queryBadges.toLowerCase().split(',').includes(encodeUrlTailParamsForFilters(badge.nameDe).toLowerCase()),
        );
    if (searchingBadges?.length) {
      searchQueryData.selectedBadges = searchingBadges;
    }
  }
  if (allergich?.length && queryAllergens?.length) {
    const searchingAllergich: ISelectedFilters = allergich.filter((allergich) =>
      queryAllergens.toLowerCase().split(',').includes(encodeUrlTailParamsForFilters(allergich.nameDe).toLowerCase()),
    );
    if (searchingAllergich?.length) {
      searchQueryData.selectedAllergich = searchingAllergich;
    }
  }
  if (nutrion?.length && queryNutrition?.length) {
    const searchingNutrion: ISelectedFilters = nutrion.filter((nutrion) =>
      queryNutrition.toLowerCase().split(',').includes(encodeUrlTailParamsForFilters(nutrion.nameDe).toLowerCase()),
    );
    if (searchingNutrion?.length) {
      searchQueryData.selectedNutrion = searchingNutrion;
    }
  }
  if (queryHasPriceRanges) {
    searchQueryData.hasPriceRanges = true;
  }
  if (queryHasBadges) {
    searchQueryData.hasBadges = true;
  }
  if (queryHasSalePrice) {
    searchQueryData.hasSalePrice = true;
  }
  if (querySearchFilter?.length) {
    searchQueryData.searchFilter = querySearchFilter;
  }
  if (queryProductSearchFilter?.length) {
    searchQueryData.productSearchFilter = queryProductSearchFilter;
  }
  if (querySortDirection?.length) {
    searchQueryData.sortDirection = querySortDirection;
  }
  if (queryCustomCategory?.length) {
    searchQueryData.customCategory = queryCustomCategory;
  }

  return { brands, badges, allergich, nutrion, searchQueryData };
};

export default useFilterDataFromSearchParams;
