import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsFilters, selectorsFilters } from '../../../../redux/filters/filtersReducer';
import { selectorsLocale } from '../../../../redux/locale/localeReducer';
import { IAttribute, IBrand } from '../../../../typings/IFilters';
import { buildLocale } from '../../../../utils/buildLocale';

const SelectedDesktopFilters = () => {
  //NOTE: mock data
  const dispatch = useDispatch<any>();
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAlergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedCustomCategoryFilter = useSelector(selectorsFilters.getSelectedCustomCategory);
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  if (totalCount === 0) {
    return null;
  }

  const removeBrand = (brand: IBrand | IAttribute) => {
    const newFilters = selectedBrands.slice(0).filter((f) => f.id !== brand.id);
    dispatch(actionsFilters.setSelectedBrands(newFilters));
  };

  const removeBadge = (badge: IBrand | IAttribute) => {
    const newFilters = selectedBadges.slice(0).filter((f) => f.id !== badge.id);
    dispatch(actionsFilters.setSelectedBadges(newFilters));
  };

  const removeAllergich = (allergich: IBrand | IAttribute) => {
    const newFilters = selectedAlergich.slice(0).filter((f) => f.id !== allergich.id);
    dispatch(actionsFilters.setSelectedAllergich(newFilters));
  };

  const removeNutrion = (nutrion: IBrand | IAttribute) => {
    const newFilters = selectedNutrion.slice(0).filter((f) => f.id !== nutrion.id);
    dispatch(actionsFilters.setSelectedNutrion(newFilters));
  };

  const removePriceRanges = () => {
    dispatch(actionsFilters.setHasPriceRanges(false));
  };

  const removeHasBadges = () => {
    dispatch(actionsFilters.setHasBadges(false));
  };

  const removeHasSalePrice = () => {
    dispatch(actionsFilters.setHasSalePrice(false));
  };

  const removeSearchFilter = () => {
    dispatch(actionsFilters.setSearchFilter(null));
  };

  const removeCustomCategoryFilter = () => {
    dispatch(actionsFilters.setCustomCategory(null));
    dispatch(actionsFilters.setSelectedCustomCategory(null));
  };

  return (
    <div className="category-filters_active">
      <div className="container">
        <span className="caption-title">{buildLocale(currentTranslate, 'filtersActiveFilters')}</span>
        <ul className="category-filters_list">
          {selectedBrands.map((brand) => (
            <li className="category-filters_item" key={brand.id}>
              <span className="caption">{brand.nameDe}</span>
              <button className="filter-close" onClick={() => removeBrand(brand)}></button>
            </li>
          ))}
          {selectedBadges.map((badge) => (
            <li className="category-filters_item" key={badge.id}>
              <span className="caption">{badge.nameDe}</span>
              <button className="filter-close" onClick={() => removeBadge(badge)}></button>
            </li>
          ))}
          {selectedAlergich.map((badge) => (
            <li className="category-filters_item" key={badge.id}>
              <span className="caption">{badge.nameDe}</span>
              <button className="filter-close" onClick={() => removeAllergich(badge)}></button>
            </li>
          ))}
          {selectedNutrion.map((badge) => (
            <li className="category-filters_item" key={badge.id}>
              <span className="caption">{badge.nameDe}</span>
              <button className="filter-close" onClick={() => removeNutrion(badge)}></button>
            </li>
          ))}
          {hasPriceRanges && (
            <li className="category-filters_item" key={'hasPriceRanges'}>
              <span className="caption">{buildLocale(currentTranslate, 'filtersHasPriceRanges')}</span>
              <button className="filter-close" onClick={() => removePriceRanges()}></button>
            </li>
          )}
          {hasBadges && (
            <li className="category-filters_item" key={'hasBadges'}>
              <span className="caption">{buildLocale(currentTranslate, 'justBioFilter')}</span>
              <button className="filter-close" onClick={() => removeHasBadges()}></button>
            </li>
          )}
          {hasSalePrice && (
            <li className="category-filters_item" key={'hasSalePrice'}>
              <span className="caption">{buildLocale(currentTranslate, 'hasSalePriceFilter')}</span>
              <button className="filter-close" onClick={() => removeHasSalePrice()}></button>
            </li>
          )}
          {searchFilter && (
            <li className="category-filters_item" key={'searchFilter'}>
              <span className="caption">
                {buildLocale(currentTranslate, 'hasSearchFilter')}: {searchFilter}
              </span>
              <button className="filter-close" onClick={() => removeSearchFilter()}></button>
            </li>
          )}
          {selectedCustomCategoryFilter && (
            <li className="category-filters_item" key={selectedCustomCategoryFilter.id}>
              <span className="caption">{selectedCustomCategoryFilter.name}</span>
              <button className="filter-close" onClick={() => removeCustomCategoryFilter()}></button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SelectedDesktopFilters;
