import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { actionsFilters, selectorsFilters } from '../../../../../redux/filters/filtersReducer';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import { AttributesType, FILTERS_TYPE, IBrands, IMainFilters } from '../../../../../typings/IFilters';
import { buildLocale } from '../../../../../utils/buildLocale';
import { compareArraysById } from '../../../../../utils/compareArraysById';
import { isBrowser } from '../../../../../utils/isBrowser';
import CloseTopBtn from '../CloseTopBtn';
import FiltersComponent from './FiltersComponents/FiltersComponent';
import FiltersMainComponent from './FiltersComponents/FiltersMainComponent';
import { useLocation } from 'react-router-dom';
import createHashFromFilter from '../../../../../utils/createHashFromFilter';
import { useNavigate, useNavigationType } from 'react-router';
import { IUseApiQuery } from '../../../../../typings/IUseApiQuery';
import useFilterDataFromSearchParams from '../../../../../useHooks/useFilterDataFromSearchParams';
import { actionsOther, selectorsOther } from '../../../../../redux/other/otherReducer';

import isLocationForFirstLoadedPage from '../../../../../utils/isLocationForFirstLoadedPage';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  applyFilters: (empty?: boolean) => void;
  brandsQuery: IUseApiQuery;
  badgesQuery: IUseApiQuery;
  attributesQuery: IUseApiQuery;
  disabledFilters?: string[];
  filtersCount: number;
}

const FiltersComponentMobile = (props: IProps) => {
  const { isOpen, setIsOpen, applyFilters, brandsQuery, badgesQuery, attributesQuery, disabledFilters, filtersCount } =
    props;
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );
  const skipBrands = !!brandsQuery?.options?.skip;
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasPriceRangesDraft = useSelector(selectorsFilters.getHasPriceRangesDraft);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasBadgesDraft = useSelector(selectorsFilters.getHasPriceBadgesDraft);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const hasSalePriceDraft = useSelector(selectorsFilters.getHasSalePriceDraft);
  const brandsDraft = useSelector(selectorsFilters.getBrandsDraft);
  const badgesDraft = useSelector(selectorsFilters.getBadgesDraft);
  const allergichDraft = useSelector(selectorsFilters.getAllergichDraft);
  const nutrionDraft = useSelector(selectorsFilters.getNutrionDraft);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const searchFilterDraft = useSelector(selectorsFilters.getSearchFilterDraft);
  const sortDirection = useSelector(selectorsFilters.getSortDirection);
  const searchProductFilter = useSelector(selectorsOther.getProductsSearchQuery);
  const customCategory = useSelector(selectorsFilters.getCustomCategory);

  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch: any = useDispatch();
  const [isOpenMain, setIsOpenMain] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<IMainFilters | null>(null);
  const [selectedFiltersArray, setSelectedFiltersArray] = useState<IBrands | AttributesType>([]);

  const isSearchPage = location.pathname.includes('all_products') || location.pathname.includes('searching');

  useEffect(() => {
    const history = location.state?.from || [];
    if (!history?.length) {
      return;
    }
    if (!location.search?.length) {
      dispatch(actionsFilters.clearAllSelected());
      return;
    }

    const historyFilters = location.state.filters;
    if (!historyFilters) {
      return;
    }

    const actualPathName = location.pathname;
    const lastLink = history.at(history.length - 1);
    if (lastLink !== `${actualPathName}${location.search}`) {
      return;
    }

    const filtersForHistoryLink = historyFilters.hasOwnProperty(actualPathName) ? historyFilters[actualPathName] : null;
    if (!filtersForHistoryLink) {
      return;
    }
    dispatch(actionsFilters.setSelectedBrands(filtersForHistoryLink.selectedBrands));
    dispatch(actionsFilters.setSelectedBadges(filtersForHistoryLink.selectedBadges));
    dispatch(actionsFilters.setSelectedAllergich(filtersForHistoryLink.selectedAllergich));
    dispatch(actionsFilters.setHasPriceRanges(filtersForHistoryLink.hasPriceRanges));
    dispatch(actionsFilters.setHasBadges(filtersForHistoryLink.hasBadges));
    dispatch(actionsFilters.setHasSalePrice(filtersForHistoryLink.hasSalePrice));
    dispatch(actionsFilters.setSortDirection(filtersForHistoryLink.sortDirection));
    dispatch(actionsFilters.setSearchFilter(filtersForHistoryLink.searchFilter));
    dispatch(actionsFilters.setCustomCategory(filtersForHistoryLink.customCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  useEffect(() => {
    setContainerHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMain]);

  useEffect(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      if (searchQueryData.isEmpty) {
        dispatch(actionsFilters.clearAllSelected());
      } else {
        dispatch(actionsFilters.setSelectedBrands(searchQueryData.selectedBrands || []));
        dispatch(actionsFilters.setSelectedBadges(searchQueryData.selectedBadges || []));
        dispatch(actionsFilters.setSelectedAllergich(searchQueryData.selectedAllergich || []));
        dispatch(actionsFilters.setSelectedNutrion(searchQueryData.selectedNutrion || []));
        dispatch(actionsFilters.setHasPriceRanges(searchQueryData.hasPriceRanges || false));
        dispatch(actionsFilters.setHasBadges(searchQueryData.hasBadges || false));
        dispatch(actionsFilters.setHasSalePrice(searchQueryData.hasSalePrice || false));
        dispatch(actionsFilters.setSearchFilter(searchQueryData.searchFilter || ''));
        dispatch(actionsFilters.setSortDirection(searchQueryData.sortDirection));
        dispatch(actionsFilters.setCustomCategory(searchQueryData.customCategory));

        if (isSearchPage) {
          dispatch(actionsOther.setProductsSearchQuery(searchQueryData.productSearchFilter || ''));
        }
      }
      return;
    }
    if (skipBrands) {
      dispatch(actionsFilters.setSelectedBrands([]));
    }
    dispatch(
      actionsFilters.setAllDraft({
        brandsDraft: searchQueryData.isEmpty ? selectedBrands : searchQueryData.selectedBrands,
        badgesDraft: searchQueryData.isEmpty ? selectedBadges : searchQueryData.selectedBadges,
        allergichDraft: searchQueryData.isEmpty ? selectedAllergich : searchQueryData.selectedAllergich,
        nutrionDraft: searchQueryData.isEmpty ? selectedNutrion : searchQueryData.selectedNutrion,
        hasBadgesDraft: searchQueryData.isEmpty ? hasBadges : searchQueryData.hasBadges,
        hasPriceRangesDraft: searchQueryData.isEmpty ? hasPriceRanges : searchQueryData.hasPriceRanges,
        hasSalePriceDraft: searchQueryData.isEmpty ? hasSalePrice : searchQueryData.hasSalePrice,
        sortDirectionDraft: searchQueryData.isEmpty ? sortDirection : searchQueryData.sortDirection,
        customCategoryDraft: searchQueryData.isEmpty ? customCategory : searchQueryData.customCategory,
      }),
    );
    dispatch(
      actionsFilters.setSearchFilterDraft(searchQueryData.isEmpty ? searchFilter : searchQueryData.searchFilter),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateUlrTail = () => {
    let queryTail: string =
      createHashFromFilter('brands', selectedBrands) +
      createHashFromFilter('badges', selectedBadges) +
      createHashFromFilter('allergens', selectedAllergich) +
      createHashFromFilter('nutrition', selectedNutrion);

    const tailParams: string[] = [];
    if (hasPriceRanges) {
      tailParams.push(`hasPriceRanges=${hasPriceRanges}`);
    }
    if (hasBadges) {
      tailParams.push(`hasBadges=${hasBadges}`);
    }
    if (hasSalePrice) {
      tailParams.push(`hasSalePrice=${hasSalePrice}`);
    }
    if (searchFilter) {
      tailParams.push(`searchFilter=${searchFilter}`);
    }
    if (isSearchPage && searchProductFilter) {
      tailParams.push(`search=${searchProductFilter}`);
    }
    if (sortDirection) {
      tailParams.push(`sort=${sortDirection}`);
    }
    if (customCategory) {
      tailParams.push(`customCategory=${customCategory}`);
    }

    if (tailParams?.length) {
      queryTail += tailParams.join('&');
    }
    const newSearchTail = queryTail.replace(/&$/, '');
    const navigationLink = `${location.pathname}${newSearchTail?.length ? '?' + newSearchTail : ''}`;

    if (location.state) {
      const selectedFilters = {
        selectedBrands,
        selectedBadges,
        selectedAllergich,
        hasPriceRanges,
        hasBadges,
        hasSalePrice,
        sortDirection,
        searchFilter,
        customCategory,
      };
      const newStateData = {
        from: location.state.from,
        filters: { ...location.state.filters, ...{ [location.pathname]: selectedFilters } },
      };
      navigate(navigationLink, { state: newStateData });
      return;
    }
    navigate(navigationLink);
  };

  useEffect(() => {
    generateUlrTail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
    sortDirection,
    customCategory,
  ]);

  useEffect(() => {
    if (isSearchPage) {
      generateUlrTail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProductFilter]);

  const onClose = () => {
    setIsOpen(false);
  };

  const selectFilter = (filter: IMainFilters) => {
    setIsOpenMain(false);
    setSelectedFilter(filter);
  };

  const getItemsByType = () => {
    if (!selectedFilter) {
      return [];
    }
    switch (selectedFilter.type) {
      case FILTERS_TYPE.BRANDS: {
        return brands;
      }
      case FILTERS_TYPE.BADGES: {
        return badges;
      }
      case FILTERS_TYPE.ALLERGICH: {
        return allergich;
      }
      case FILTERS_TYPE.NUTRION: {
        return nutrion;
      }
      default:
        return [];
    }
  };

  const changeFilterByType = () => {
    if (!selectedFilter) {
      return;
    }
    switch (selectedFilter.type) {
      case FILTERS_TYPE.BRANDS: {
        dispatch(actionsFilters.setSelectedBrands(selectedFiltersArray));
        return;
      }
      case FILTERS_TYPE.BADGES: {
        dispatch(actionsFilters.setSelectedBadges(selectedFiltersArray));
        return;
      }
      case FILTERS_TYPE.ALLERGICH: {
        dispatch(actionsFilters.setSelectedAllergich(selectedFiltersArray));
        return;
      }
      case FILTERS_TYPE.NUTRION: {
        dispatch(actionsFilters.setSelectedNutrion(selectedFiltersArray));
        return;
      }
      default:
        return;
    }
  };

  const setCurrentFilterType = () => {
    if (!selectedFilter) {
      return [];
    }
    switch (selectedFilter.type) {
      case FILTERS_TYPE.BRANDS: {
        return selectedBrands;
      }
      case FILTERS_TYPE.BADGES: {
        return selectedBadges;
      }
      case FILTERS_TYPE.ALLERGICH: {
        return selectedAllergich;
      }
      case FILTERS_TYPE.NUTRION: {
        return selectedNutrion;
      }
      default:
        return [];
    }
  };

  const setBtnTitle = () => {
    if (
      isOpenMain &&
      (!compareArraysById(selectedBrands, brandsDraft) ||
        !compareArraysById(selectedBadges, badgesDraft) ||
        !compareArraysById(selectedAllergich, allergichDraft) ||
        !compareArraysById(selectedNutrion, nutrionDraft) ||
        hasPriceRanges !== hasPriceRangesDraft ||
        hasBadges !== hasBadgesDraft ||
        hasSalePrice !== hasSalePriceDraft ||
        searchFilter !== searchFilterDraft)
    ) {
      return buildLocale(currentTranslate, 'filtersBtnShowProducts');
    }
    if (
      isOpenMain &&
      compareArraysById(selectedBrands, brandsDraft) &&
      compareArraysById(selectedBadges, badgesDraft) &&
      compareArraysById(selectedBadges, allergichDraft) &&
      compareArraysById(selectedBadges, nutrionDraft) &&
      hasPriceRanges === hasPriceRangesDraft &&
      hasBadges === hasBadgesDraft &&
      hasSalePrice === hasSalePriceDraft &&
      searchFilter === searchFilterDraft
    ) {
      return buildLocale(currentTranslate, 'filtersBtnClose');
    }
    if (!isOpenMain && compareArraysById(setCurrentFilterType(), selectedFiltersArray)) {
      return buildLocale(currentTranslate, 'filtersBtnBack');
    }
    return buildLocale(currentTranslate, 'filtersBtnApply');
  };

  const closeMain = () => {
    setSelectedFilter(null);
    setIsOpenMain(true);
  };

  const setBtnAction = () => {
    if (!isOpenMain && !compareArraysById(setCurrentFilterType(), selectedFiltersArray)) {
      changeFilterByType();
      closeMain();
      return;
    }
    if (!isOpenMain && compareArraysById(setCurrentFilterType(), selectedFiltersArray)) {
      closeMain();
      return;
    }
    applyFilters();
    setIsOpen(false);
    return;
  };

  const setContainerHeight = () => {
    const container = document.getElementById('modal-filter-container');
    if (container) {
      container.style.height = !isOpenMain && isBrowser ? `90%` : 'auto';
    }
  };

  return (
    <>
      {!isOpen && (
        <div className="mobile-filter_one filter" onClick={() => setIsOpen(true)}>
          {filtersCount ? <span className="number">{filtersCount}</span> : null}
          <span className="caption">{buildLocale(currentTranslate, 'filtersFilterTitle')}</span>
        </div>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} className="modal-filter">
          <div className={`modal-filter_container`} id="modal-filter-container">
            <CloseTopBtn onClose={onClose} />
            {isOpenMain ? (
              <FiltersMainComponent selectFilter={selectFilter} disabledFilters={disabledFilters} />
            ) : (
              <FiltersComponent
                items={getItemsByType()}
                selectedFilter={selectedFilter}
                setSelectedFiltersArray={setSelectedFiltersArray}
                selectedFiltersArray={selectedFiltersArray}
              />
            )}
            <button
              className={`btn ${selectedFiltersArray.length === 0 && !isOpenMain ? 'btn-border btn-orange-border' : ''}`}
              onClick={() => setBtnAction()}>
              {setBtnTitle()}
            </button>
          </div>
          {/* NOTE: more functionality with backend */}
        </Modal>
      )}
    </>
  );
};

export default FiltersComponentMobile;
