import { FILTER_TYPE, IFiltersDTO } from '../typings/IFilters';
import { IUseApiQuery } from '../typings/IUseApiQuery';
import { useSelector } from 'react-redux';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import queries from '../services/queries';

interface FilterQueryParamsProps {
  type: string;
  id?: number;
  search?: string;
  skip?: boolean;
  filters?: IFiltersDTO;
}

const useFilterQueryParams = (props: FilterQueryParamsProps) => {
  const { type, id, search, filters } = props;
  const abortController = new AbortController();
  const signal = abortController.signal;
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  const sortItemsByName = (data: { id: number; nameDe: string }[], state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    return data.sort((a, b) => a.nameDe.localeCompare(b.nameDe));
  };

  let brandsQuery: IUseApiQuery = id
    ? { query: queries.getOwnCategoryBrands(signal, isInCourierArea, id), options: { skip: props.skip } }
    : ({ options: { skip: props.skip } } as IUseApiQuery);
  let badgesQuery: IUseApiQuery = id
    ? { query: queries.getOwnCategoryBadges(signal, isInCourierArea, id), options: { skip: props.skip } }
    : ({ options: { skip: props.skip } } as IUseApiQuery);
  let attributesQuery = {
    query: queries.getCategoryAttributes(signal, isInCourierArea),
    options: { skip: props.skip },
  };

  if (type === FILTER_TYPE.SEARCH && search) {
    brandsQuery.query = queries.getSearchBrands(signal, search, isInCourierArea);
  } else if (type === FILTER_TYPE.CATEGORY) {
    brandsQuery.query = queries.getCategoryBrands(signal, isInCourierArea, filters, id);
    brandsQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.WISHLIST) {
    brandsQuery.query = queries.getWishlistBrands(signal, isInCourierArea);
  } else if (type === FILTER_TYPE.BRAND) {
    brandsQuery.options!.skip = true;
  }

  if (type === FILTER_TYPE.SEARCH && search) {
    badgesQuery.query = queries.getSearchBadges(signal, search, isInCourierArea);
  } else if (type === FILTER_TYPE.CATEGORY) {
    badgesQuery.query = queries.getCategoryBadges(signal, isInCourierArea, filters, id);
    badgesQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.WISHLIST) {
    badgesQuery.query = queries.getWishlistBadges(signal, isInCourierArea);
  } else if (type === FILTER_TYPE.BRAND) {
    badgesQuery.query = queries.getBrandBadges(signal, isInCourierArea, id);
  }

  if (type === FILTER_TYPE.WISHLIST) {
    attributesQuery.query = queries.getWishlistAttributes(signal, isInCourierArea);
  }

  return { brandsQuery, badgesQuery, attributesQuery, abortController };
};

export default useFilterQueryParams;
