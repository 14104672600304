import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsFilters, selectorsFilters } from '../../../../../../redux/filters/filtersReducer';
import { selectorsLocale } from '../../../../../../redux/locale/localeReducer';
import { FILTERS_TYPE, IMainFilters } from '../../../../../../typings/IFilters';
import { buildLocale } from '../../../../../../utils/buildLocale';
import FiltersMainItem from './FiltersMainItem';

interface IProps {
  selectFilter: (filter: IMainFilters) => void;
  disabledFilters?: string[];
}

const FiltersMainComponent = (props: IProps) => {
  const { selectFilter, disabledFilters } = props;
  const dispatch = useDispatch<any>();
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedCustomCategory = useSelector(selectorsFilters.getSelectedCustomCategory);
  const [hasSelectedCustomCategory, setHasSelectedCustomCategory] = useState(false);
  const searchFilterLength = searchFilter ? searchFilter.length : 0;
  const filtersCount = totalCount - searchFilterLength;

  useEffect(() => {
    dispatch(actionsFilters.setHasPriceRanges(hasPriceRanges));
    dispatch(actionsFilters.setHasBadges(hasBadges));
    dispatch(actionsFilters.setHasSalePrice(hasSalePrice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasSelectedCustomCategory(!!selectedCustomCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomCategory]);

  //NOTE: mock data
  const filters = [
    {
      id: 1,
      title: buildLocale(currentTranslate, 'filtersBrandsTitle'),
      type: FILTERS_TYPE.BRANDS,
    },
    {
      id: 2,
      title: buildLocale(currentTranslate, 'filtersBadgesTitle'),
      type: FILTERS_TYPE.BADGES,
    },
    {
      id: 3,
      title: buildLocale(currentTranslate, 'filtersAllergicTitle'),
      type: FILTERS_TYPE.ALLERGICH,
    },
    {
      id: 4,
      title: buildLocale(currentTranslate, 'filtersErnahrungTitle'),
      type: FILTERS_TYPE.NUTRION,
    },
  ];

  const clearAllFilters = () => {
    dispatch(actionsFilters.clearAllSelected());
  };

  return (
    <div className="mobile-filter_result">
      {filtersCount ? (
        <div className="mobile-filter_result-row">
          <span className="title">
            {buildLocale(currentTranslate, 'filterFilterMainMobile')} <span className="number">{filtersCount}</span>
          </span>
          <span className="mobile-filter_clear" onClick={() => clearAllFilters()}>
            {buildLocale(currentTranslate, 'filtersClearAll')}
          </span>
        </div>
      ) : (
        <div className="mobile-filter_result-row">
          <span className="title">{buildLocale(currentTranslate, 'filterFilterMainMobile')}</span>
        </div>
      )}
      <div>
        {filters.length > 0 &&
          filters
            .filter((filter) => !disabledFilters?.includes(filter.type))
            .map((filter) => <FiltersMainItem key={filter.id} filter={filter} selectFilter={selectFilter} />)}
      </div>
      {hasSelectedCustomCategory && (
        <div className="mobile-filter_checkbox">
          <input
            id={'selected-custom-category'}
            checked={hasSelectedCustomCategory}
            type="checkbox"
            onChange={() => {
              dispatch(actionsFilters.setCustomCategory(null));
              setHasSelectedCustomCategory(false);
            }}
          />
          <label htmlFor={'selected-custom-category'}>{selectedCustomCategory?.name}</label>
        </div>
      )}
      <div className="mobile-filter_checkbox">
        <input
          id={'has-price-ranges'}
          checked={hasPriceRanges}
          type="checkbox"
          onChange={() => dispatch(actionsFilters.setHasPriceRanges(!hasPriceRanges))}
        />
        <label htmlFor={'has-price-ranges'}>{buildLocale(currentTranslate, 'filtersHasPriceRanges')}</label>
      </div>
      <div className="mobile-filter_checkbox">
        <input
          id={'has-badges'}
          checked={hasBadges}
          type="checkbox"
          onChange={() => dispatch(actionsFilters.setHasBadges(!hasBadges))}
        />
        <label htmlFor={'has-badges'}>{buildLocale(currentTranslate, 'justBioFilter')}</label>
      </div>
      <div className="mobile-filter_checkbox">
        <input
          id={'has-sale-price'}
          checked={hasSalePrice}
          type="checkbox"
          onChange={() => dispatch(actionsFilters.setHasSalePrice(!hasSalePrice))}
        />
        <label htmlFor={'has-sale-price'}>{buildLocale(currentTranslate, 'hasSalePriceFilter')}</label>
      </div>
    </div>
  );
};

export default FiltersMainComponent;
